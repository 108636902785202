// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
* ::-webkit-scrollbar-track {
  background: none;
}
* ::-webkit-scrollbar-thumb {
  background: #ccc;
}
* ::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}
* ::-webkit-scrollbar-thumb:active {
  background: #6e6e6e;
}
* ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

body {
  font-family: "Nunito", sans-serif;
}
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  margin: 0;
}
body p {
  margin: 0;
}
body a {
  text-decoration: none;
}
body ol,
body ul,
body li {
  margin: 0;
  padding: 0;
  list-style: none;
}
body button,
body input,
body textarea {
  font-family: "Nunito", sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/App.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,SAAA;EACA,UAAA;AACF;AACE;EACE,gBAAA;AACJ;AAEE;EACE,gBAAA;AAAJ;AAEI;EACE,gBAAA;AAAN;AAGI;EACE,mBAAA;AADN;AAKE;EACE,UAAA;EACA,WAAA;AAHJ;;AAOA;EACE,iCAAA;AAJF;AAME;;;;;;EAME,SAAA;AAJJ;AAOE;EACE,SAAA;AALJ;AAQE;EACE,qBAAA;AANJ;AASE;;;EAGE,SAAA;EACA,UAAA;EACA,gBAAA;AAPJ;AAUE;;;EAGE,iCAAA;AARJ","sourcesContent":["* {\n  box-sizing: border-box;\n  margin: 0;\n  padding: 0;\n\n  ::-webkit-scrollbar-track {\n    background: none;\n  }\n\n  ::-webkit-scrollbar-thumb {\n    background: #ccc;\n\n    &:hover {\n      background: #aaa;\n    }\n\n    &:active {\n      background: #6e6e6e;\n    }\n  }\n\n  ::-webkit-scrollbar {\n    width: 6px;\n    height: 6px;\n  }\n}\n\nbody {\n  font-family: \"Nunito\", sans-serif;\n\n  h1,\n  h2,\n  h3,\n  h4,\n  h5,\n  h6 {\n    margin: 0;\n  }\n\n  p {\n    margin: 0;\n  }\n\n  a {\n    text-decoration: none;\n  }\n\n  ol,\n  ul,\n  li {\n    margin: 0;\n    padding: 0;\n    list-style: none;\n  }\n\n  button,\n  input,\n  textarea {\n    font-family: \"Nunito\", sans-serif;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
