import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FormControlLabel,
  LinearProgress,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";

import { getRatesList, setFilter } from "../../redux/slices/RatesSlice";

import Header from "../../components/Header";
import Overlay from "../../components/Overlay";

import styles from "./Rates.module.scss";

const Rates = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const { rates, filter } = useSelector((state) => state.rates);
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getRatesList());
  }, []);

  React.useEffect(() => {
    dispatch(getRatesList());
  }, [filter]);

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
    dispatch(setFilter({ name: "page", value: newPage + 1 }));
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    dispatch(setFilter({ name: "page_size", value: e.target.value }));
    setPage(0);
  };

  const columns = [
    {
      id: "carrier",
      label: "Carrier",
      minWidth: 200,
      name: "carrier",
      value: filter.carrier,
      inputLabel: "Contains",
    },
    {
      id: "contract",
      label: "Contract",
      minWidth: 200,
      name: "contract",
      value: filter.contract,
      inputLabel: "Contains",
    },
    {
      id: "origin_country",
      label: "Origin country",
      minWidth: 200,
      name: "origin_country",
      value: filter.origin_country,
      inputLabel: "Contains",
    },
    {
      id: "departure_point",
      label: "Departure point",
      minWidth: 200,
      name: "departure_point",
      value: filter.departure_point,
      inputLabel: "Contains",
    },
    {
      id: "via",
      label: "Via",
      minWidth: 200,
      name: "via",
      value: filter.via,
      inputLabel: "Contains",
    },
    {
      id: "delivery_point",
      label: "Delivery point",
      minWidth: 200,
      name: "delivery_point",
      value: filter.delivery_point,
      inputLabel: "Contains",
    },
    {
      id: "destination_point",
      label: "Destination point",
      minWidth: 200,
      name: "destination_point",
      value: filter.destination_point,
      inputLabel: "Contains",
    },
    {
      id: "delivery_terms",
      label: "Delivery terms",
      minWidth: 200,
      name: "delivery_terms",
      value: filter.delivery_terms,
      inputLabel: "Contains",
    },
    {
      id: "transport_type",
      label: "Transport type",
      minWidth: 200,
      name: "transport_type",
      value: filter.transport_type,
      inputLabel: "Contains",
    },
    {
      id: "rate_type",
      label: "Rate type",
      minWidth: 200,
      name: "rate_type",
      value: filter.rate_type,
      inputLabel: "Contains",
    },
    {
      id: "rate",
      label: "Rate",
      minWidth: 200,
      type: "number",
      name: "rate",
      value: filter.rate,
      inputLabel: "Contains",
    },
    {
      id: "valid_from",
      label: "Valid from",
      minWidth: 200,
      type: "date",
      name: "valid_from",
      value: filter.valid_from,
      inputLabel: "Contains",
      shrink: true,
    },
    {
      id: "valid_till",
      label: "Valid till",
      minWidth: 200,
      type: "date",
      name: "valid_till",
      value: filter.valid_till,
      inputLabel: "Contains",
      shrink: true,
    },
    {
      id: "status",
      label: "Status",
      minWidth: 200,
      name: "status",
      value: filter.status,
      inputLabel: "Contains",
    },
  ];

  return (
    <>
      <Header />
      {/* <Overlay /> */}
      {rates ? (
        <Paper sx={{ width: "100%", overflow: "hidden", borderRadius: "0" }}>
          <TableContainer className={styles.tableCntr}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <>
                      {column.name === "rate_type" ||
                      column.name === "status" ? (
                        <></>
                      ) : (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            verticalAlign: "top",
                            fontWeight: "bold",
                          }}
                        >
                          {column.label}
                          <TextField
                            type={column.type}
                            label={column.inputLabel}
                            variant="standard"
                            value={column.value}
                            onChange={(e) =>
                              dispatch(
                                setFilter({
                                  name: column.name,
                                  value: e.target.value,
                                })
                              )
                            }
                            InputLabelProps={{
                              shrink: column.shrink,
                            }}
                            style={column.style}
                          />
                        </TableCell>
                      )}

                      {column.name === "status" && (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            verticalAlign: "top",
                            fontWeight: "bold",
                          }}
                        >
                          {column.label}
                          <RadioGroup
                            row
                            aria-labelledby="demo-form-control-label-placement"
                            name="position"
                            defaultValue="top"
                          >
                            <FormControlLabel
                              value="top"
                              control={
                                <Radio
                                  id="all"
                                  value="a"
                                  name={column.name}
                                  checked={filter.status === "" ? true : false}
                                  onChange={(e) =>
                                    dispatch(
                                      setFilter({
                                        name: column.name,
                                        value: "",
                                      })
                                    )
                                  }
                                  size="small"
                                  style={{ padding: 0 }}
                                />
                              }
                              label="All"
                              labelPlacement="top"
                              style={{ margin: 0 }}
                            />
                            <FormControlLabel
                              value="top"
                              control={
                                <Radio
                                  id="active"
                                  value="b"
                                  name={column.name}
                                  checked={
                                    filter.status === "true" ? true : false
                                  }
                                  onChange={(e) =>
                                    dispatch(
                                      setFilter({
                                        name: column.name,
                                        value: "true",
                                      })
                                    )
                                  }
                                  size="small"
                                  style={{ padding: 0 }}
                                />
                              }
                              label="Active"
                              labelPlacement="top"
                              style={{ margin: "0 0 0 15px" }}
                            />
                            <FormControlLabel
                              value="top"
                              control={
                                <Radio
                                  id="inactive"
                                  value="b"
                                  name={column.name}
                                  checked={
                                    filter.status === "false" ? true : false
                                  }
                                  onChange={(e) =>
                                    dispatch(
                                      setFilter({
                                        name: column.name,
                                        value: "false",
                                      })
                                    )
                                  }
                                  size="small"
                                  style={{ padding: 0 }}
                                />
                              }
                              label="Inactive"
                              labelPlacement="top"
                              style={{ margin: "0 0 0 15px" }}
                            />
                          </RadioGroup>
                        </TableCell>
                      )}

                      {column.name === "rate_type" &&
                        user.groups.includes("centrum_manager") && (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              verticalAlign: "top",
                              fontWeight: "bold",
                            }}
                          >
                            {column.label}
                            <TextField
                              type={column.type}
                              label={column.inputLabel}
                              variant="standard"
                              value={column.value}
                              onChange={(e) =>
                                dispatch(
                                  setFilter({
                                    name: column.name,
                                    value: e.target.value,
                                  })
                                )
                              }
                              InputLabelProps={{
                                shrink: column.shrink,
                              }}
                              style={column.style}
                            />
                          </TableCell>
                        )}
                    </>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rates.results.map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map((column) => {
                        let value = row[column.id];
                        if (column.name === "status") {
                          value = value ? "active" : "inactive";
                        }
                        return (
                          <>
                            {column.name === "rate_type" ? (
                              <></>
                            ) : (
                              <TableCell key={column.id} align={column.align}>
                                {column.format && typeof value === "number"
                                  ? column.format(value)
                                  : value}
                              </TableCell>
                            )}

                            {column.name === "rate_type" &&
                              user.groups.includes("centrum_manager") && (
                                <TableCell key={column.id} align={column.align}>
                                  {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              )}
                          </>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 30, 50, 100]}
            component="div"
            count={rates.count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        <LinearProgress color="inherit" />
      )}
    </>
  );
};

export default Rates;
