import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Avatar,
  Button,
  Tooltip,
  MenuItem,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import { logout } from "../../redux/slices/AuthSlice";
import { getTracingList, resetFilter } from "../../redux/slices/TracingSlice";
import {
  getRatesList,
  resetFilter as resetRateFilter,
} from "../../redux/slices/RatesSlice";

import styles from "./Header.module.scss";

function Header() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { t, i18n } = useTranslation();
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const location = useLocation();

  const open = Boolean(anchorEl);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("selectedLanguage", lng);
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logout());
    window.location.href = "https://uzlogistic.uz/";
  };

  const handleResetTracing = async () => {
    dispatch(resetFilter());
    await dispatch(getTracingList());
  };

  const handleResetRates = async () => {
    dispatch(resetRateFilter());
    await dispatch(getRatesList());
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xxl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem onClick={handleCloseNavMenu}>
                <Link to="/" className={styles.link} style={{ color: "#000" }}>
                  {t("header.tracing")}
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  to="/rates"
                  className={styles.link}
                  style={{ color: "#000" }}
                >
                  {t("header.rates")}
                </Link>
              </MenuItem>
              <MenuItem>
                {location.pathname === "/rates" ? (
                  <Button
                    onClick={handleResetRates}
                    variant="contained"
                    size="small"
                  >
                    {t("header.filter")}
                  </Button>
                ) : (
                  <Button
                    onClick={handleResetTracing}
                    variant="contained"
                    size="small"
                  >
                    {t("header.filter")}
                  </Button>
                )}
              </MenuItem>
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <Link to="/" className={styles.link}>
              {t("header.tracing")}
            </Link>
            <Link
              to="/rates"
              className={styles.link}
              style={{ marginLeft: "15px" }}
            >
              {t("header.rates")}
            </Link>
            {location.pathname === "/rates" ? (
              <Button
                onClick={handleResetRates}
                variant="contained"
                size="small"
                style={{ marginLeft: "15px" }}
              >
                {t("header.filter")}
              </Button>
            ) : (
              <Button
                onClick={handleResetTracing}
                variant="contained"
                size="small"
                style={{ marginLeft: "15px" }}
              >
                {t("header.filter")}
              </Button>
            )}
          </Box>
          <div style={{ marginRight: "15px" }}>
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              onClick={handleClick}
              className={styles.langBtn}
            >
              {t("language")}
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => changeLanguage("en")}
                className={styles.menuBtn}
              >
                EN
              </MenuItem>
              <MenuItem
                onClick={() => changeLanguage("ru")}
                className={styles.menuBtn}
              >
                RU
              </MenuItem>
            </Menu>
          </div>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp">
                  {user.first_name[0]}
                  {user.last_name[0]}
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <List className={styles.list}>
                <ListItem className={styles.listItem}>
                  <ListItemText
                    primary={user.first_name + " " + user.last_name}
                    secondary={user.organization}
                  />
                </ListItem>
              </List>
              <MenuItem onClick={handleCloseUserMenu}>
                <Typography textAlign="center" onClick={handleLogout}>
                  {t("header.sign_out")}
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Header;
