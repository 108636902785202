import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  tracing: undefined,
  filter: {
    page: 1,
    page_size: 100,
    cont_num: "",
    cont_type: "",
    route: "",
    location: "",
    dep_from: "",
    dep_to: "",
    del_from: "",
    del_to: "",
    lot_no: "",
    customer: "",
    consignee: "",
    freight_type: "",
    supplier_name: "",
    supplier_inv: "",
    item: "",
    cargo_type: "",
    customer_request: "",
    final_port: "",
    transhipment_port: "",
    wagon_truck_no: "",
    request_type: "",
  },
};

const { REACT_APP_API_ENDPOINT } = process.env;

export const getTracingList = createAsyncThunk(
  "get/tracing/list",
  async (params, api) => {
    const access = api.getState().auth;
    const { filter } = api.getState().tracing;
    let rqst = ``;
    let i = 0;
    for (const [key, value] of Object.entries(filter)) {
      if (value) {
        rqst += i === 0 ? `?${key}=${value}` : `&${key}=${value}`;
      }
      i++;
    }
    const resp = await axios.get(
      `${REACT_APP_API_ENDPOINT}/api/websheet/tracing/${rqst}`,
      {
        headers: {
          Authorization: "Token ".concat(access.tokens.access),
        },
      }
    );

    return resp.data;
  }
);

const TracingSlice = createSlice({
  name: "tracing",
  initialState: initialState,

  reducers: {
    setFilter: (state, action) => {
      if (action.payload.name === "page")
        state.filter.page = action.payload.value;
      if (action.payload.name === "page_size")
        state.filter.page_size = action.payload.value;
      if (action.payload.name === "cont_num")
        state.filter.cont_num = action.payload.value;
      if (action.payload.name === "cont_type")
        state.filter.cont_type = action.payload.value;
      if (action.payload.name === "route")
        state.filter.route = action.payload.value;
      if (action.payload.name === "location")
        state.filter.location = action.payload.value;
      if (action.payload.name === "dep_from")
        state.filter.dep_from = action.payload.value;
      if (action.payload.name === "dep_to")
        state.filter.dep_to = action.payload.value;
      if (action.payload.name === "del_from")
        state.filter.del_from = action.payload.value;
      if (action.payload.name === "del_to")
        state.filter.del_to = action.payload.value;
      if (action.payload.name === "lot_no")
        state.filter.lot_no = action.payload.value;
      if (action.payload.name === "customer")
        state.filter.customer = action.payload.value;
      if (action.payload.name === "consignee")
        state.filter.consignee = action.payload.value;
      if (action.payload.name === "freight_type")
        state.filter.freight_type = action.payload.value;
      if (action.payload.name === "supplier_name")
        state.filter.supplier_name = action.payload.value;
      if (action.payload.name === "supplier_inv")
        state.filter.supplier_inv = action.payload.value;
      if (action.payload.name === "item")
        state.filter.item = action.payload.value;
      if (action.payload.name === "cargo_type")
        state.filter.cargo_type = action.payload.value;
      if (action.payload.name === "customer_request")
        state.filter.customer_request = action.payload.value;
      if (action.payload.name === "final_port")
        state.filter.final_port = action.payload.value;
      if (action.payload.name === "transhipment_port")
        state.filter.transhipment_port = action.payload.value;
      if (action.payload.name === "wagon_truck_no")
        state.filter.wagon_truck_no = action.payload.value;
      if (action.payload.name === "request_type")
        state.filter.request_type = action.payload.value;
    },
    resetFilter: (state) => {
      state.filter.page = 1;
      state.filter.page_size = 100;
      state.filter.cont_num = "";
      state.filter.cont_type = "";
      state.filter.route = "";
      state.filter.location = "";
      state.filter.dep_from = "";
      state.filter.dep_to = "";
      state.filter.del_from = "";
      state.filter.del_to = "";
      state.filter.lot_no = "";
      state.filter.customer = "";
      state.filter.consignee = "";
      state.filter.freight_type = "";
      state.filter.supplier_name = "";
      state.filter.supplier_inv = "";
      state.filter.item = "";
      state.filter.cargo_type = "";
      state.filter.customer_request = "";
      state.filter.final_port = "";
      state.filter.transhipment_port = "";
      state.filter.wagon_truck_no = "";
      state.filter.request_type = "";
    },
  },

  extraReducers: {
    [getTracingList.fulfilled]: (state, action) => {
      state.tracing = action.payload;
    },
    [getTracingList.rejected]: () => {
      console.log("Get Tracing List Rejected");
    },
  },
});

export const { setFilter, resetFilter } = TracingSlice.actions;
export default TracingSlice.reducer;
