import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  rates: undefined,
  filter: {
    page: 1,
    page_size: 10,
    carrier: "",
    contract: "",
    origin_country: "",
    departure_point: "",
    via: "",
    delivery_point: "",
    destination_point: "",
    delivery_terms: "",
    transport_type: "",
    rate_type: "",
    rate: "",
    valid_from: "",
    valid_till: "",
    status: "",
  },
};

const { REACT_APP_API_ENDPOINT } = process.env;

export const getRatesList = createAsyncThunk(
  "get/rates/list",
  async (props, api) => {
    const access = api.getState().auth;
    const { filter } = api.getState().rates;
    let rqst = ``;
    let i = 0;
    for (const [key, value] of Object.entries(filter)) {
      if (value) {
        rqst += i === 0 ? `?${key}=${value}` : `&${key}=${value}`;
      }
      i++;
    }
    const resp = await axios.get(
      `${REACT_APP_API_ENDPOINT}/api/websheet/rates/${rqst}`,
      {
        headers: {
          Authorization: "Token ".concat(access.tokens.access),
        },
      }
    );
    return resp.data;
  }
);

const RatesSlice = createSlice({
  name: "rates",
  initialState: initialState,
  reducers: {
    resetRates: () => initialState,
    resetFilter: (state) => {
      state.filter.page = 1;
      state.filter.page_size = 10;
      state.filter.carrier = "";
      state.filter.contract = "";
      state.filter.origin_country = "";
      state.filter.departure_point = "";
      state.filter.via = "";
      state.filter.delivery_point = "";
      state.filter.destination_point = "";
      state.filter.delivery_terms = "";
      state.filter.transport_type = "";
      state.filter.rate_type = "";
      state.filter.rate = "";
      state.filter.valid_from = "";
      state.filter.valid_till = "";
      state.filter.status = "";
    },
    setFilter: (state, action) => {
      if (action.payload.name === "page")
        state.filter.page = action.payload.value;
      if (action.payload.name === "page_size")
        state.filter.page_size = action.payload.value;
      if (action.payload.name === "carrier")
        state.filter.carrier = action.payload.value;
      if (action.payload.name === "contract")
        state.filter.contract = action.payload.value;
      if (action.payload.name === "origin_country")
        state.filter.origin_country = action.payload.value;
      if (action.payload.name === "departure_point")
        state.filter.departure_point = action.payload.value;
      if (action.payload.name === "via")
        state.filter.via = action.payload.value;
      if (action.payload.name === "delivery_point")
        state.filter.delivery_point = action.payload.value;
      if (action.payload.name === "destination_point")
        state.filter.destination_point = action.payload.value;
      if (action.payload.name === "delivery_terms")
        state.filter.delivery_terms = action.payload.value;
      if (action.payload.name === "transport_type")
        state.filter.transport_type = action.payload.value;
      if (action.payload.name === "rate_type")
        state.filter.rate_type = action.payload.value;
      if (action.payload.name === "rate")
        state.filter.rate = action.payload.value;
      if (action.payload.name === "valid_from")
        state.filter.valid_from = action.payload.value;
      if (action.payload.name === "valid_till")
        state.filter.valid_till = action.payload.value;
      if (action.payload.name === "status")
        state.filter.status = action.payload.value;
    },
  },
  extraReducers: {
    [getRatesList.fulfilled]: (state, action) => {
      state.rates = action.payload;
    },
    [getRatesList.rejected]: () => {
      alert("Get Rates: Failed");
    },
  },
});

export const { resetRates, setFilter, resetFilter } = RatesSlice.actions;

export default RatesSlice.reducer;
