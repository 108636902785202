// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `header {
  background-color: #284480 !important;
}

.Header_link__qAkBV {
  font-size: 16px;
  color: #fff;
}
.Header_link__qAkBV:hover {
  color: #d4d4d4;
}
.Header_link__qAkBV:last-child {
  margin-left: 15px;
}

.Header_list__y-y9L {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.Header_list__y-y9L .Header_listItem__poLxP {
  padding-top: 0 !important;
}

.Header_langBtn__ROVFO {
  min-width: 0 !important;
  padding: 5px 10px !important;
}

.Header_menuBtn__rWRri {
  padding: 5px 10px !important;
  font-size: 14px !important;
}

.Header_logoutBtn__iSlnw {
  display: flex;
  align-items: center;
}
.Header_logoutBtn__iSlnw svg {
  margin-left: 5px;
}

@media (max-width: 599px) {
  .Header_link__qAkBV {
    font-size: 14px;
  }
  .Header_list__y-y9L .Header_listItem__poLxP span {
    font-size: 14px;
  }
  .Header_logoutBtn__iSlnw {
    font-size: 14px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.module.scss"],"names":[],"mappings":"AAAA;EACE,oCAAA;AACF;;AAEA;EACE,eAAA;EACA,WAAA;AACF;AACE;EACE,cAAA;AACJ;AAEE;EACE,iBAAA;AAAJ;;AAIA;EACE,yBAAA;EACA,4BAAA;AADF;AAGE;EACE,yBAAA;AADJ;;AAKA;EACE,uBAAA;EACA,4BAAA;AAFF;;AAKA;EACE,4BAAA;EACA,0BAAA;AAFF;;AAKA;EACE,aAAA;EACA,mBAAA;AAFF;AAIE;EACE,gBAAA;AAFJ;;AAMA;EACE;IACE,eAAA;EAHF;EAQI;IACE,eAAA;EANN;EAWA;IACE,eAAA;EATF;AACF","sourcesContent":["header {\n  background-color: #284480 !important;\n}\n\n.link {\n  font-size: 16px;\n  color: #fff;\n\n  &:hover {\n    color: #d4d4d4;\n  }\n\n  &:last-child {\n    margin-left: 15px;\n  }\n}\n\n.list {\n  padding-top: 0 !important;\n  padding-bottom: 0 !important;\n\n  .listItem {\n    padding-top: 0 !important;\n  }\n}\n\n.langBtn {\n  min-width: 0 !important;\n  padding: 5px 10px !important;\n}\n\n.menuBtn {\n  padding: 5px 10px !important;\n  font-size: 14px !important;\n}\n\n.logoutBtn {\n  display: flex;\n  align-items: center;\n\n  svg {\n    margin-left: 5px;\n  }\n}\n\n@media (max-width: 599px) {\n  .link {\n    font-size: 14px;\n  }\n\n  .list {\n    .listItem {\n      span {\n        font-size: 14px;\n      }\n    }\n  }\n\n  .logoutBtn {\n    font-size: 14px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": `Header_link__qAkBV`,
	"list": `Header_list__y-y9L`,
	"listItem": `Header_listItem__poLxP`,
	"langBtn": `Header_langBtn__ROVFO`,
	"menuBtn": `Header_menuBtn__rWRri`,
	"logoutBtn": `Header_logoutBtn__iSlnw`
};
export default ___CSS_LOADER_EXPORT___;
