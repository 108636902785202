// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Overlay_overlay__mpfM- {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  background: #fff;
}
.Overlay_overlay__mpfM- .Overlay_overlayContent__oJmF9 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  background: #284480;
  border-radius: 0.375rem;
}
.Overlay_overlay__mpfM- .Overlay_overlayContent__oJmF9 .Overlay_btnBlock__oBKqn a {
  margin-right: 5px;
}
.Overlay_overlay__mpfM- .Overlay_overlayContent__oJmF9 .Overlay_btnBlock__oBKqn button {
  margin-left: 5px;
}

@media (max-width: 599px) {
  .Overlay_overlay__mpfM- .Overlay_overlayContent__oJmF9 .Overlay_btnBlock__oBKqn a {
    font-size: 12px;
  }
  .Overlay_overlay__mpfM- .Overlay_overlayContent__oJmF9 .Overlay_btnBlock__oBKqn button {
    font-size: 12px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Overlay/Overlay.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,eAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;AACF;AACE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACJ;AAEM;EACE,iBAAA;AAAR;AAGM;EACE,gBAAA;AADR;;AAOA;EAIQ;IACE,eAAA;EAPR;EAUM;IACE,eAAA;EARR;AACF","sourcesContent":[".overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  z-index: 999999;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n  padding: 0 10px;\n  background: #fff;\n\n  .overlayContent {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    padding: 20px;\n    background: #284480;\n    border-radius: 0.375rem;\n\n    .btnBlock {\n      a {\n        margin-right: 5px;\n      }\n\n      button {\n        margin-left: 5px;\n      }\n    }\n  }\n}\n\n@media (max-width: 599px) {\n  .overlay {\n    .overlayContent {\n      .btnBlock {\n        a {\n          font-size: 12px;\n        }\n  \n        button {\n          font-size: 12px;\n        }\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `Overlay_overlay__mpfM-`,
	"overlayContent": `Overlay_overlayContent__oJmF9`,
	"btnBlock": `Overlay_btnBlock__oBKqn`
};
export default ___CSS_LOADER_EXPORT___;
