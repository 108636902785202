import React from "react";
import i18n, { changeLanguage } from "i18next";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import { initReactI18next } from "react-i18next";

import { store } from "./redux/store";

import Login from "./pages/Login";
import MainPage from "./pages/MainPage";
import Rates from "./pages/Rates";

import enTranslations from "./translations/en.json";
import ruTranslations from "./translations/ru.json";

import "./assets/scss/App.scss";

const PrivateRoutes = (props) => {
  const { user } = useSelector((state) => state.auth);
  return user ? props.children : <Navigate to={"/login"} />;
};

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: (
      <PrivateRoutes>
        <MainPage />
      </PrivateRoutes>
    ),
  },
  {
    path: "/rates",
    element: (
      <PrivateRoutes>
        <Rates />
      </PrivateRoutes>
    ),
  },
]);

function App() {
  i18n.use(initReactI18next).init({
    resources: {
      en: {
        translation: enTranslations,
      },
      ru: {
        translation: ruTranslations,
      },
    },
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

  React.useEffect(() => {
    const selectedLanguage = localStorage.getItem("selectedLanguage") || "en";
    changeLanguage(selectedLanguage);
  }, []);

  return (
    <Provider store={store}>
      <div className="App">
        <RouterProvider router={router} />
      </div>
    </Provider>
  );
}

export default App;
