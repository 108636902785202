import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";

import { getTracingList, setFilter } from "../../redux/slices/TracingSlice";

import Header from "../../components/Header";

import styles from "./MainPage.module.scss";

const MainPage = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const { tracing, filter } = useSelector((state) => state.tracing);
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getTracingList());
  }, []);

  React.useEffect(() => {
    dispatch(getTracingList());
  }, [filter]);

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
    dispatch(setFilter({ name: "page", value: newPage + 1 }));
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    dispatch(setFilter({ name: "page_size", value: e.target.value }));
    setPage(0);
  };

  const columns = [
    {
      id: "customer_request",
      label: "Customer request",
      minWidth: 200,
      name: "customer_request",
      value: filter.customer_request,
      inputLabel: "Contains",
      hidden: { display: "none" },
    },
    {
      id: "consignee",
      label: "Consignee",
      minWidth: 200,
      name: "consignee",
      value: filter.consignee,
      inputLabel: "Contains",
      hidden: { display: "none" },
    },
    {
      id: "freight_type",
      label: "Freight type",
      minWidth: 200,
      name: "freight_type",
      value: filter.freight_type,
      inputLabel: "Contains",
      hidden: { display: "none" },
    },
    {
      id: "supplier_name",
      label: "Supplier name",
      minWidth: 200,
      name: "supplier_name",
      value: filter.supplier_name,
      inputLabel: "Contains",
      hidden: { display: "none" },
    },
    {
      id: "supplier_inv",
      label: "Supplier inv",
      minWidth: 200,
      name: "supplier_inv",
      value: filter.supplier_inv,
      inputLabel: "Contains",
      hidden: { display: "none" },
    },
    {
      id: "lot_no",
      label: "Lot no",
      minWidth: 200,
      name: "lot_no",
      value: filter.lot_no,
      inputLabel: "Contains",
      hidden: { display: "none" },
    },
    {
      id: "cont_num",
      label: "Transporation",
      minWidth: 200,
      name: "cont_num",
      value: filter.cont_num,
      inputLabel: "Contains",
      hidden: { display: "none" },
    },
    {
      id: "cont_type",
      label: "Type",
      minWidth: 200,
      name: "cont_type",
      value: filter.cont_type,
      inputLabel: "Contains",
      hidden: { display: "none" },
    },
    {
      id: "item",
      label: "Item",
      minWidth: 200,
      name: "item",
      value: filter.item,
      inputLabel: "Contains",
      hidden: { display: "none" },
    },
    {
      id: "carrier_cbm",
      label: "Carrier cbm",
      minWidth: 200,
      name: "carrier_cbm",
      value: filter.carrier_cbm,
      style: { display: "none" },
      hidden: { display: "none" },
    },
    {
      id: "carrier_gwh",
      label: "Carrier gwh",
      minWidth: 200,
      name: "carrier_gwh",
      value: filter.carrier_gwh,
      style: { display: "none" },
      hidden: { display: "none" },
    },
    {
      id: "cargo_type",
      label: "Cargo type",
      minWidth: 200,
      name: "cargo_type",
      value: filter.cargo_type,
      inputLabel: "Contains",
      hidden: { display: "none" },
    },
    {
      id: "final_port",
      label: "Final port",
      minWidth: 200,
      name: "final_port",
      value: filter.final_port,
      inputLabel: "Contains",
      hidden: { display: "none" },
    },
    {
      id: "transhipment_port",
      label: "Transhipment port",
      minWidth: 200,
      name: "transhipment_port",
      value: filter.transhipment_port,
      inputLabel: "Contains",
      hidden: { display: "none" },
    },
    {
      id: "route",
      label: "Route",
      minWidth: 200,
      name: "route",
      value: filter.route,
      inputLabel: "Contains",
      hidden: { display: "none" },
    },
    {
      id: "departure_date",
      label: "Departure date",
      minWidth: 200,
      type: "date",
      name: "dep_from",
      value: filter.dep_from,
      name2: "dep_to",
      value2: filter.dep_to,
      inputLabel: "From",
      shrink: true,
    },
    {
      id: "wagon_truck_no",
      label: "Wagon truck no",
      minWidth: 200,
      name: "wagon_truck_no",
      value: filter.wagon_truck_no,
      inputLabel: "Contains",
      hidden: { display: "none" },
    },
    {
      id: "delivery_date",
      label: "Delivery date",
      type: "date",
      minWidth: 200,
      name: "del_from",
      value: filter.del_from,
      name2: "del_to",
      value2: filter.del_to,
      inputLabel: "From",
      shrink: true,
    },
    {
      id: "request_type",
      label: "Request type",
      minWidth: 200,
      name: "request_type",
      value: filter.request_type,
      inputLabel: "Contains",
      hidden: { display: "none" },
    },
    {
      id: "location",
      label: "Location",
      minWidth: 200,
      name: "location",
      value: filter.location,
      inputLabel: "Contains",
      hidden: { display: "none" },
    },
    {
      id: "customer",
      label: "Customer",
      minWidth: 200,
      name: "customer",
      value: filter.customer,
      inputLabel: "Contains",
      hidden: { display: "none" },
    },
  ];

  return (
    <>
      <Header />
      {tracing ? (
        <Paper sx={{ width: "100%", overflow: "hidden", borderRadius: "0" }}>
          <TableContainer className={styles.tableCntr}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        verticalAlign: "top",
                        fontWeight: "bold",
                      }}
                    >
                      {column.label}
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {column.name === "customer" ||
                        column.name === "consignee" ||
                        column.name === "request_type" ? (
                          <></>
                        ) : (
                          <TextField
                            type={column.type}
                            label={column.inputLabel}
                            variant="standard"
                            value={column.value}
                            onChange={(e) =>
                              dispatch(
                                setFilter({
                                  name: column.name,
                                  value: e.target.value,
                                })
                              )
                            }
                            InputLabelProps={{
                              shrink: column.shrink,
                            }}
                            style={column.style}
                          />
                        )}

                        {column.name === "customer" &&
                          user.groups.includes("centrum_manager") && (
                            <TextField
                              type={column.type}
                              label={column.inputLabel}
                              variant="standard"
                              value={column.value}
                              onChange={(e) =>
                                dispatch(
                                  setFilter({
                                    name: column.name,
                                    value: e.target.value,
                                  })
                                )
                              }
                              InputLabelProps={{
                                shrink: column.shrink,
                              }}
                              style={column.style}
                            />
                          )}

                        {column.name === "consignee" && (
                          <>
                            {user.groups.includes("centrum_manager") && (
                              <TextField
                                type={column.type}
                                label={column.inputLabel}
                                variant="standard"
                                value={column.value}
                                onChange={(e) =>
                                  dispatch(
                                    setFilter({
                                      name: column.name,
                                      value: e.target.value,
                                    })
                                  )
                                }
                                InputLabelProps={{
                                  shrink: column.shrink,
                                }}
                                style={column.style}
                              />
                            )}

                            {user.groups.includes("customer") && (
                              <TextField
                                type={column.type}
                                label={column.inputLabel}
                                variant="standard"
                                value={column.value}
                                onChange={(e) =>
                                  dispatch(
                                    setFilter({
                                      name: column.name,
                                      value: e.target.value,
                                    })
                                  )
                                }
                                InputLabelProps={{
                                  shrink: column.shrink,
                                }}
                                style={column.style}
                              />
                            )}
                          </>
                        )}

                        {column.name === "request_type" && (
                          <>
                            {!user.profile_type && (
                              <TextField
                                type={column.type}
                                label={column.inputLabel}
                                variant="standard"
                                value={column.value}
                                onChange={(e) =>
                                  dispatch(
                                    setFilter({
                                      name: column.name,
                                      value: e.target.value,
                                    })
                                  )
                                }
                                InputLabelProps={{
                                  shrink: column.shrink,
                                }}
                                style={column.style}
                              />
                            )}
                          </>
                        )}

                        <TextField
                          type={column.type}
                          label="to"
                          variant="standard"
                          value={column.value2}
                          onChange={(e) =>
                            dispatch(
                              setFilter({
                                name: column.name2,
                                value: e.target.value,
                              })
                            )
                          }
                          InputLabelProps={{
                            shrink: column.shrink,
                          }}
                          style={column.hidden}
                        />
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tracing.results.map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[100, 300, 500, 1000]}
            component="div"
            count={tracing.count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        <LinearProgress color="inherit" />
      )}
    </>
  );
};

export default MainPage;
