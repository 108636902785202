import axios from "axios";
import jwtDecode from "jwt-decode";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  user: localStorage.getItem("authToken")
    ? jwtDecode(JSON.parse(localStorage.getItem("authToken")).access)
    : undefined,
  tokens: localStorage.getItem("authToken")
    ? JSON.parse(localStorage.getItem("authToken"))
    : undefined,
};

const { REACT_APP_API_ENDPOINT } = process.env;

export const getAccess = createAsyncThunk(
  "access/token",
  async (params, api) => {
    const { username, password } = params;
    const resp = await axios.post(`${REACT_APP_API_ENDPOINT}/api/token/`, {
      username,
      password,
    });
    return resp.data;
  }
);

export const AuthSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem("authToken");
      state.user = undefined;
      state.tokens = undefined;
    },
  },
  extraReducers: {
    [getAccess.fulfilled]: (state, action) => {
      state.tokens = action.payload;
      state.user = jwtDecode(state.tokens.access);
      localStorage.setItem("authToken", JSON.stringify(action.payload));
    },
    [getAccess.rejected]: () => {
      alert("Authentication failed!!!");
    },
  },
});

export const { logout } = AuthSlice.actions;
export default AuthSlice.reducer;
