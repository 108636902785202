import { configureStore } from '@reduxjs/toolkit';
import AuthSlice from './slices/AuthSlice';
import TracingSlice from './slices/TracingSlice';
import RatesSlice from './slices/RatesSlice';

export const store = configureStore({
  reducer: {
    auth: AuthSlice,
    tracing: TracingSlice,
    rates: RatesSlice,
  },
});
