// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Rates_tableCntr__Z5X4C {
  height: calc(100vh - 116px);
}

@media (max-width: 599px) {
  .Rates_tableCntr__Z5X4C {
    height: calc(100vh - 108px);
  }
}
@media (max-width: 410px) {
  .Rates_tableCntr__Z5X4C {
    height: calc(100vh - 114px);
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Rates/Rates.module.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;AACF;;AAEA;EACE;IACE,2BAAA;EACF;AACF;AAEA;EACE;IACE,2BAAA;EAAF;AACF","sourcesContent":[".tableCntr {\n  height: calc(100vh - 116px);\n}\n\n@media (max-width: 599px) {\n  .tableCntr {\n    height: calc(100vh - 108px);\n  }\n}\n\n@media (max-width: 410px) {\n  .tableCntr {\n    height: calc(100vh - 114px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableCntr": `Rates_tableCntr__Z5X4C`
};
export default ___CSS_LOADER_EXPORT___;
